<template>
  <div />
</template>

<script>
import { setToken, setOriginalUserUid } from '@/utils/token'

export default {
  name: 'SwitchUser',
  created() {
    this.$store.dispatch('ClearLoginInfo')
    setToken(this.$route.query.token)
    setOriginalUserUid(this.$route.query.original_user)
    this.$store.dispatch('SetIsNeedCheckAutoAlertNotification', true)
    this.$router.push({ name: 'home' })
  }
}
</script>
